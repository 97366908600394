<template>
    <v-container>
      <div class="header_holder text-center ma">
        <div class="rounder_top mb-2">
            <div class="card_header ubuntu_b primer dark_color">
                <router-link to="/shop">
                    <v-icon class="dark_color">
                        fas fa-angle-left
                    </v-icon>
                </router-link ><span class="text-center slesh mx-4">/</span> 
                {{ main_cat.name }}
            </div>
        </div>
      </div>
      <v-row dense class="my-8">
          <v-col cols="12" v-for="cat in categories" :key="cat.id">
            <router-link :to="'/products/'+cat.id" class="category">
              <v-btn class="mx-2 category_btn">{{cat.name}}</v-btn>
            </router-link>
          </v-col>
          <v-col cols="12">
              <div class="text-right mt-3 mb-3">
                <router-link to="/cart" v-if="cart_items.length">
                  <v-btn text small> 
                    <v-icon small class="mr-3">fas fa-shopping-cart</v-icon>
                    Košarica
                  </v-btn>
                </router-link>
              </div>
          </v-col>
      </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['category'],
  computed: {
    ...mapGetters({
      cart_items: 'cart/cartProducts',
      cats: 'products/getCategories',
      main_cats: 'products/getMainCategories',
      sub_cats: 'products/getSubCategories'
    }),
    categories(){
        return this.sub_cats(this.$route.params.category)
    },
    main_cat(){
        return this.main_cats.find((i)=>i.id === parseInt(this.$route.params.category))
    }
  },
  beforeCreate(){
    if(this.$store.getters['user/getCredentials'].narucivanje == 0) this.$router.push({ name: 'not_auth' })
    this.$store.dispatch('products/getCategories', /*this.$route.params.category_id*/)
  }
}
</script>
<style scoped>
  .slesh{
    width:60px;
  }
  .card_header, .dark_color{
    color:#3B3A39 !important
  }
  .category, .category_btn{
      width:100%
  }
</style>